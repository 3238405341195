<template>
    <div>
        <div class="text-center">
            <div style="font-size: 16px;"><strong>Proyección de Ventas</strong></div>
            <br />
        </div>        
        <CDataTable
            :hover="true"
            :striped="true"
            :border="true"
            :small="true"
            :fixed="true"
            :items="items"
            :fields="fields"
            :items-per-page="30"
            pagination
            :noItemsView="{ noResults: 'No se encontraron elementos', noItems: 'Sin Elementos' }"
            >         
              <template #brand="{item}">
                <td class="table_actions">
                  <strong>{{ item.brand }}</strong>
                </td>
              </template>   
              <template #first_year="{item}">
                <td class="text-right">
                  ${{ item.first_year }}
                </td>
              </template>   
              <template #last_year="{item}">
                <td class="text-right">
                  <strong>${{ item.last_year }}</strong>
                </td>
              </template>   
              <template #so="{item}">
                <td class="text-right">
                  ${{ item.so }}
                </td>
              </template>   
              <template #projection="{item}">
                <td class="text-right">
                  ${{ item.projection }}
                </td>
              </template>   
              <template #variation="{item}">
                <td class="text-right">
                  <span v-if="!item.variation_plus">-</span> ${{ item.variation }}
                </td>
              </template>   
              <template #variation_rate="{item}">
                <td class="text-right" :class="setColor(item.variation_plus)">
                  <span v-if="!item.variation_plus">-</span> {{ item.variation_rate }}%
                </td>
              </template>               
        </CDataTable>
    </div>
</template>

<script>
// import ws from '@/services/graphs';

import ws from '../../services/dashboards';
import store from '../../store'

export default {
    name: 'ProjectionTable',
    props: {
        permissions: {
            type: Object,
            default() {
                return {}
            }
        },
        title: {
            type: String,
            default() {
                return "Proyección de Ventas"
            }
        },
        month: {
            type: String,
            default: '202401'
        }           
    },
    components : {
	},
    data: function () {
		    return {
            data: {},
            items: [],
            root: "",
            chart: "",
            xAxis:"",
            yAxis:"",
            wheelX: false,
            wheelY: false,
            data_response:"",
            legend:"",
            fields: [
              {key: 'brand', label: 'Proveedor (Marca)'},
              {key: 'first_year', label: '2023'},
              {key: 'last_year', label: '2024'},
              {key: 'projection', label: 'Proyección'},
              {key: 'variation_rate', label: 'Var %'},
              {key: 'variation', label: 'Var $'}
            ]  
        }
    },
    computed: {
        
    },
    mounted: async function(){        
        this.loading(); 

        let response = await ws.getProjectionSales(this.month);

        if(response.type == "success"){
            this.data = response.data.data;

            this.fields[1].label = response.data.label_2;
            this.fields[2].label = response.data.label_1;

            this.items = this.data;

            this.loaded();
        }

        this.loaded();
    },
    methods: {
        async reloadGraph(month, brand){
            this.loading();

            let response = await ws.getProjectionSales(month, brand);

          if(response.type == "success"){
              this.data = response.data.data;

              this.fields[1].label = response.data.label_2;
              this.fields[2].label = response.data.label_1;

              this.items = this.data;

              this.loaded();
          }

            this.loaded();
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        },
        setColor (value) {
            let $color
            if (value) {
                $color = 'good';        
            } else {
                $color = 'bad';
            }
            return $color
        }
    },
    beforeDestroy() {
        if (this.root) {
            this.root.dispose();
        }
  }
}

</script>
